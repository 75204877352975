import React from 'react';
import { Spinner } from 'reactstrap';

export const Loading = () => {
  return (
    <div
      data-cy="loading"
      className="h-100 w-100 d-flex justify-content-center align-items-center"
    >
      <Spinner>Loading...</Spinner>
    </div>
  );
};

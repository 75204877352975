import { H5PEditorUI } from '@lumieducation/h5p-react';
import { Loading } from 'components';
import { useContentEditor } from 'hooks/content/useContentEditor';
import { useAppStore } from 'hooks/useAppStore';
import { useInterval } from 'hooks/useInterval';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMeasure } from 'react-use';

export const ContentEditorTab = observer(() => {
  const { id: contentId } = useParams();
  const [searchParams] = useSearchParams();
  const library = searchParams.get('library');
  const { editorSaveContentCallback } = useContentEditor();
  const {
    contentStore,
    contentStore: { isContentLoaded, isDirty, editor },
  } = useAppStore();

  const h5pEditorRef = useRef<H5PEditorUI>(null);

  const [ref, { height }] = useMeasure();

  useEffect(() => {
    (window.opener || window.parent)?.postMessage({ subject: 'lti.frameResize', height: height }, '*');
  }, [height]);

  useEffect(() => {
    contentStore.setH5PEditorRef(h5pEditorRef);
    return () => {
      contentStore.clearH5PEditorRef();
    };
  }, []);

  // Listen for changes in editor params and metadata every second, stop when there is a change.
  useInterval(
    () => {
      const { params, metadata } = (h5pEditorRef.current as any)?.h5pEditor?.current?.editorInstance?.getParams() || {};
      if (params && metadata) {
        contentStore.storeEditor({ params, metadata });
      }
    },
    1000,
    isDirty,
  );

  useLayoutEffect(() => {
    return () => {
      // Save content params and metadata to store when unmount
      const { params, metadata } = (h5pEditorRef.current as any)?.h5pEditor?.current?.editorInstance?.getParams() || {};
      if (params && metadata) {
        contentStore.storeEditor({ params, metadata });
      }
    };
  }, []);

  if (!library && !contentId) return <></>;

  return (
    <div ref={ref}>
      {!isContentLoaded ? <Loading /> : <></>}

      <H5PEditorUI
        ref={h5pEditorRef}
        contentId={contentId || 'new-content'}
        library={library}
        loadContentCallback={(contentId: string) =>
          contentStore.loadEditorAsync(contentId, contentId === 'new-content' ? library : editor?.library)
        }
        saveContentCallback={async (contentId, requestBody) => {
          return await editorSaveContentCallback(contentId, requestBody);
        }}
        onSaveError={() => {
          toast('Content title is required', { type: 'error' });
        }}
      />
    </div>
  );
});

import { ROLES_PERMISSIONS, Role } from '@spiderbox/common';

export const flattenPermissions = (permissions, role) => {
  if (role === Role.MEMBER) return {};

  const result = {};

  const flattenHelper = (obj, prefix) => {
    for (const key in obj) {
      const newKey = !prefix ? key : key === 'all' ? `${prefix}` : `${prefix}.${key}`;

      if (typeof obj[key] === 'object') {
        flattenHelper(obj[key], newKey);
      } else {
        result[newKey] = obj[key];
      }
    }
  };
  flattenHelper(permissions, '');

  return result;
};

export const convertRolePermission = (permissions: { [key: string]: boolean }, role: Role) => {
  if (!Object.keys(ROLES_PERMISSIONS).includes(role)) return {};

  const newPermissions = ROLES_PERMISSIONS[role]?.map(permission => {
    return {
      ...permission,
      active: !!Object.keys(permission).length ? permissions[permission.action] : true,
    };
  });

  return newPermissions;
};

import React from 'react';
import './LibraryCardContent.scss';
import { Library } from 'types';
import { UncontrolledTooltip } from 'reactstrap';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useModal from 'hooks/useModal';
import { useAppStore } from 'hooks/useAppStore';
import classNames from 'classnames';

interface Props {
  library: Library;
  index?: number;
}

export const LibraryCardContent: React.FC<Props> = ({ library, index }) => {
  const { closeModal } = useModal();
  const {
    ltiStore: { workspaceId },
    ltiMode,
  } = useAppStore();
  const navigate = useNavigate();
  const { folderId = '', id: contentId = '' } = useParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { state } = location;

  const addNew = () => {
    closeModal();

    const navigateFrom = state?.from ? state?.from : location.pathname;
    const shouldRefresh = !!contentId || searchParams.get('library')?.includes(library.metadata.machineName);

    const url = ltiMode ? `/${workspaceId}/lti/launch` : '';
    navigate(
      `${url}/contents/new?library=${library.metadata.machineName} ${library.metadata.majorVersion}.${library.metadata.minorVersion}&folderId=${folderId}`,
      {
        replace: true,
        state: {
          shouldRefresh,
          from: navigateFrom,
        },
      },
    );
  };

  const readMore = () => {
    console.log('readMore');
  };

  return (
    <div className="h-100 d-flex flex-column justify-content-between">
      <div
        className={classNames('d-flex gap-4 pt-4 px-4 mb-auto library-card__type', {
          'flex-column': ltiMode,
          'flex-row flex-sm-column': !ltiMode,
        })}
        data-cy="library-card-type"
      >
        <img
          className="library-card__type__img"
          src={library?.image}
          data-cy="library-card__type__img"
        />

        <div className="library-card__type__content">
          <div
            className="fw-bold mb-1 library-card__type__content__title"
            data-cy="library-card__type__content__title"
          >
            {library?.title}
          </div>
          <div
            className="library-card__type__content__description"
            data-cy="library-card__type__content__description"
            id={'library-card-' + index}
          >
            {library?.description}

            <UncontrolledTooltip
              className="d-none d-sm-block"
              container="body"
              placement="bottom"
              target={'library-card-' + index}
            >
              {library?.description}
            </UncontrolledTooltip>
          </div>
        </div>
      </div>
      <div
        className="d-flex justify-content-end gap-2 mt-3 pb-4 px-4 library-card__btn flex-wrap"
        data-cy="library-card__btn"
      >
        <button
          type="button"
          className="btn btn-outline-primary library-card__btn__read-more flex-fill text-nowrap d-none"
          data-cy="library-card__btn__read-more"
          onClick={readMore}
        >
          Read More
        </button>

        <button
          type="button"
          className="btn btn-primary d-none d-sm-block library-card__btn__add-new flex-fill text-nowrap"
          data-cy="library-card__btn__add-new"
          onClick={addNew}
        >
          Add New
        </button>
      </div>
    </div>
  );
};

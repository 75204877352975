import TextField from 'components/AppForm/TextField';
import React, { useEffect } from 'react';
import { X } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { ModalBody } from 'reactstrap';
import { Footer } from '../Footer';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMediaScreen } from 'hooks/useMediaScreen';

export type Props = {
  onOk?: (data?: any) => void;
  onCancel: () => void;
  defaultValue?: string;
  labelOK: string;
  inputLabel?: string;
  inputPlaceHolder?: string;
  headerTitle: string;
  isLoading?: boolean;
};

export const TitleForm = ({
  onOk,
  onCancel,
  defaultValue,
  labelOK,
  headerTitle,
  inputPlaceHolder,
  inputLabel,
  isLoading,
}: Props) => {
  const TitleFormSchema = yup.object().shape({
    title: yup.string().trim().required(`${inputLabel} is required.`),
  });
  const { isMdDown } = useMediaScreen();
  const {
    control,
    formState: { errors },
    setFocus,
    handleSubmit,
  } = useForm({
    defaultValues: {
      title: defaultValue,
    },
    resolver: yupResolver(TitleFormSchema as any),
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (!isMdDown) {
      setFocus('title', { shouldSelect: true });
    }
  }, [setFocus, isMdDown]);

  return (
    <div className="d-flex flex-column p-3 create-content-modal modal-wrapper">
      <ModalBody className="d-flex flex-column gap-3 mb-3">
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="gray-900 fw-semibold font-size-18">{headerTitle}</div>
          <X
            onClick={() => onCancel?.()}
            className="close-icon gray-400 cursor-pointer d-block"
            size={24}
            data-cy="close-icon"
          />
        </div>

        <div className="form-group mt-2 font-size-12">
          <Controller
            name="title"
            control={control}
            render={({ field, formState }) => {
              return (
                <TextField
                  value={field.value}
                  placeholder={inputPlaceHolder}
                  error={formState.errors?.title}
                  label={inputLabel}
                  onChange={event => field.onChange(event.target.value)}
                  dataCy="title"
                  ref={field.ref}
                />
              );
            }}
          />

          {errors?.title?.message && (
            <p
              className="feedback-invalid"
              data-cy="invalid-name"
            >
              {errors?.title?.message}
            </p>
          )}
        </div>
      </ModalBody>

      <Footer
        labelCancel="Cancel"
        labelOK={labelOK}
        onOK={handleSubmit(onOk)}
        onCancel={() => onCancel?.()}
        isLoading={isLoading}
      />
    </div>
  );
};

import { ChevronDown, X } from 'react-feather';
import {
  ClearIndicatorProps,
  DropdownIndicatorProps,
  MultiValueRemoveProps,
  OptionProps,
  components,
} from 'react-select';
import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import 'pages/Organisation/ManageUsersPage/components/DropdownAssignRole/DropdownAssignRole.scss';
import { RoleTypeMapping } from 'types';
import classNames from 'classnames';
import { Role } from '@spiderbox/common';

export interface Option {
  readonly label: string;
  readonly value: string;
}

export const createOption = (label: string) => ({
  label,
  value: label,
});

export enum ERSColor {
  DANGER = '#ec536c',
  PRIMARY = '#1d4ed8',
  PRIMARY_50 = '#e8edfb',
  PRIMARY_100 = '#bbcaf3',
  PRIMARY_600 = '#173ead',
  NEUTRAL_200 = '#e2e8f0',
  NEUTRAL_400 = '#94A3B8',
  NEUTRAL_900 = '#0f172a',
}

export const RSClearIndicator = (props: ClearIndicatorProps) => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
    >
      <div className="pe-2">
        <X
          size={18}
          className="d-block m-auto"
        />
      </div>
    </div>
  );
};

export const RSDropdownIndicator = (props: DropdownIndicatorProps) => {
  const {
    innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
      {...restInnerProps}
      ref={ref}
    >
      <div className="pe-2 cursor-pointer">
        <ChevronDown
          size={18}
          className="d-block m-auto"
        />
      </div>
    </div>
  );
};

export const RSMultiValueRemove = (props: MultiValueRemoveProps) => {
  return (
    <components.MultiValueRemove {...props}>
      <X
        size={14}
        className="d-block m-auto"
      />
    </components.MultiValueRemove>
  );
};

export const RSOption = (props: OptionProps) => {
  const { innerProps, innerRef, data, isSelected } = props;

  const { value } = data as Option;

  const rolesDescription = {
    [Role.MEMBER]: 'Access to workspace and manage contents.',
    [Role.ADMIN]: 'Manage contents, users, billing and other organisation settings.',
  };

  return (
    <div
      ref={innerRef}
      {...innerProps}
      className="p-1"
    >
      <div
        className={classNames('d-flex border border-neutral-200 rounded p-2 dropdown-assign-role__radio', {
          'dropdown-assign-role__radio--selected': isSelected,
        })}
        data-cy={`rs-option-${value}`}
      >
        <FormGroup check>
          <Input
            id={value}
            name="role"
            type="radio"
            value={value}
            checked={isSelected}
          />
          <Label
            check
            for={value}
          >
            <div className="fw-semibold text-neutral-900">{RoleTypeMapping[value]}</div>
            <div className="fs-sm text-neutral-500 fw-normal">{rolesDescription[value]}</div>
          </Label>
        </FormGroup>
      </div>
    </div>
  );
};

import { Row } from '@tanstack/react-table';
import classNames from 'classnames';
import { AppBadge } from 'components';
import { useGetConnections, useGetOptionLibraries } from 'hooks';
import useModal from 'hooks/useModal';
import React, { useMemo } from 'react';
import { Box, FileText, Minus } from 'react-feather';
import Skeleton from 'react-loading-skeleton';
import { ContentUserDataAttempt } from 'types/student-report';
import './LearnerReportsList.scss';

export type LearnerReportListViewData = {
  id?: string;
  fullName?: string;
  totalAttempts: number;
  email?: string;
  contentTitle?: string;
  library?: string;
  platformId?: string;
  firstAttemptScore?: number;
  firstAttemptPercent?: number;
  lastAttemptScore?: number;
  lastAttemptPercent?: number;
  bestScore?: number;
  bestScorePercent?: number;
  attempts?: ContentUserDataAttempt[];
};

type IProps = {
  data: LearnerReportListViewData[];
  isLoading: boolean;
  onRowClick?: (report: any) => void;
};

export const LearnerReportsList = ({ data, isLoading, onRowClick }: IProps) => {
  const { openModal, closeModal } = useModal();
  const { data: connections, isLoading: isConnectionsLoading } = useGetConnections({ cacheTime: 300000 });

  const connectionsData = useMemo(() => {
    if (!connections?.length) return {};

    return connections.reduce((current, { kid, platformName }) => {
      return { ...current, [kid]: platformName };
    }, {});
  }, [connections]);

  const { data: libraries, isLoading: isLibrariesLoading } = useGetOptionLibraries();

  const mappingTitle = (libraryName: string) => {
    const library = libraries?.find(lib => lib.metadata.machineName === libraryName);
    return library?.title || libraryName;
  };

  const openContentAttemptsModal = (report: LearnerReportListViewData) => {
    openModal('content-attempts', {
      scrollable: true,
      onCancel: closeModal,
      title: report.contentTitle,
      library: mappingTitle(report.library),
      attempts: report.attempts,
    });
  };

  const createScoreCell = (score: number, scorePercent: number, headerText: string, best?: boolean) => {
    return (
      <div className="col-4 d-flex flex-column gap-1">
        <div className="gray-900 font-size-12">{headerText}</div>
        {isLoading ? (
          <Skeleton />
        ) : (
          <div className="d-flex align-items-center">
            {scorePercent !== null ? (
              <AppBadge
                type={best ? 'primary-light' : 'neutral-outline'}
                label={
                  <span className={classNames('text-nowrap', { 'text-neutral-700': !best })}>
                    {score} <span className="ms-1 fw-normal">({scorePercent}%)</span>
                  </span>
                }
              />
            ) : (
              <Minus size={16} />
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="learner-reports-list bg-white">
      {data?.map((report, key) => {
        return (
          <div
            className="d-flex flex-column p-3 gap-2 bg-white border-bottom border-neutral-200"
            key={key}
            onClick={() =>
              !!report.attempts?.length
                ? openContentAttemptsModal(report)
                : onRowClick({ original: report } as Row<any>)
            }
          >
            <div className="d-flex justify-content-between">
              {isLoading ? (
                <Skeleton width={200} />
              ) : (
                <div className="report-title text-neutral-700 fw-semibold pe-2">
                  {report.fullName || report.contentTitle}
                </div>
              )}

              <div className="me-0 text-nowrap">
                {isLoading ? (
                  <Skeleton width={100} />
                ) : (
                  <AppBadge
                    type="primary"
                    label={
                      <span className="font-size-14 fw-normal">
                        Attempts: <span className="fw-medium">{report.totalAttempts}</span>
                      </span>
                    }
                  />
                )}
              </div>
            </div>

            {report.email && <div className="text-neutral-700 text-truncate pe-2">{report.email}</div>}

            {isLoading || isLibrariesLoading ? (
              <Skeleton />
            ) : (
              report.library && (
                <div className="d-flex">
                  <FileText
                    className="text-neutral-700 me-2"
                    size={16}
                  />

                  <div className="text-neutral-700 font-size-12">{mappingTitle(report.library)}</div>
                </div>
              )
            )}

            {isLoading || isConnectionsLoading ? (
              <Skeleton />
            ) : (
              report.platformId && (
                <div className="d-flex">
                  <Box
                    className="text-neutral-700 me-2"
                    size={16}
                  />

                  <div className="text-neutral-700 font-size-12 flex-fill w-1px text-truncate pe-2">
                    {connectionsData[report.platformId]}
                  </div>
                </div>
              )
            )}

            {report.attempts?.length && (
              <div className="row">
                {createScoreCell(report.firstAttemptScore, report.firstAttemptPercent, 'First Score')}

                {createScoreCell(report.lastAttemptScore, report.lastAttemptPercent, 'Last Score')}

                {createScoreCell(report.bestScore, report.bestScorePercent, 'Best Score', true)}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

import { ContentLtiAction } from '@spiderbox/common';
import { useAppStore } from 'hooks/useAppStore';
import { observer } from 'mobx-react-lite';
import { AllContentsPage } from 'pages/Content/AllContentsPage';
import React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

export const LtiLaunchPage = observer(() => {
  const { ltiStore } = useAppStore();
  const [searchParam] = useSearchParams();
  const contentId = searchParam.get('resourceid') || '';
  const studentReportEmail = searchParam.get('student_report_email') || '';
  const action = searchParam.get('action') || '';
  const baseUrl = `/${ltiStore.workspaceId}/lti/launch/contents/${contentId}`;

  switch (action) {
    case ContentLtiAction.PLAY:
      return (
        <Navigate
          to={`${baseUrl}/play`}
          replace={true}
        />
      );
    case ContentLtiAction.REPORT:
      return (
        <Navigate
          to={`${baseUrl}/reports/${studentReportEmail}?${searchParam}`}
          replace={true}
        />
      );
    default:
      return <AllContentsPage />;
  }
});

import React, { useEffect, useMemo, useState } from 'react';
import { DateRangePicker } from 'components';
import SearchBar from 'components/SearchBar/SearchBar';
import { parse, stringify } from 'qs';
import { ArrowLeft, Download, Filter } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { calculateTotalFilterParams } from 'utils';
import { PageHeader } from 'components/PageHeader';
import { TableOptions } from '@tanstack/react-table';
import { LearnerReportsTable, ExpandedContentAttempts } from '../components';
import classNames from 'classnames';
import useModal from 'hooks/useModal';
import { ContentFilterModalProps } from 'types';
import { AppBreadCrumb, CrumbItem } from 'components/AppBreadCrumb';
import './LearnerReportLayout.scss';
import { useMediaScreen } from 'hooks/useMediaScreen';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'hooks/useAppStore';
import Skeleton from 'react-loading-skeleton';

type LearnerReportLayoutProps = {
  pageTitle?: string;
  queryKey: string[];
  fetchReports: (searchTerm: any, userId?: string) => Promise<any[]>;
  downloadCsv?: (searchTerm: any) => void;
  isDownloadLoading?: boolean;
  defaultColumns: any[];
  userId?: string;
  tableOptionsData?: Partial<TableOptions<any>>;
  searchInputPlaceHolder?: string;
  crumbs?: CrumbItem[];
  canFilterByContentType?: boolean;
  canFilterByPlatform?: boolean;
  onRowClick?: (row: any) => void;
  isLoading?: boolean;
};

export const LearnerReportLayout = observer((props: LearnerReportLayoutProps) => {
  const {
    pageTitle = '',
    queryKey,
    fetchReports,
    downloadCsv,
    isDownloadLoading,
    defaultColumns,
    userId,
    tableOptionsData = {},
    searchInputPlaceHolder = '',
    crumbs = [],
    canFilterByContentType = false,
    canFilterByPlatform = false,
    onRowClick,
    isLoading = false,
  } = props;
  const { openModal, closeModal } = useModal();
  const navigate = useNavigate();
  const { isLgDown } = useMediaScreen();

  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState({});

  const { control, watch } = useForm({
    defaultValues: {
      search: searchParams.get('search') || '',
    },
  });
  const { ltiMode } = useAppStore();

  const search = watch('search');

  const queryClient = useQueryClient();

  const openFilterModal = () => {
    openModal('content-filter', {
      value: {
        libraries: searchTerm.libraries,
        lastAttemptFrom: Number(searchTerm.lastAttemptFrom || ''),
        lastAttemptTo: Number(searchTerm.lastAttemptTo || ''),
      },
      canFilterByStarred: false,
      canFilterByStatus: false,
      canFilterByType: false,
      canFilterByAttemptTime: true,
      className: 'align-items-center pb-3',
      onAccept: query => {
        setFilters({
          libraries: query.libraries,
          lastAttemptFrom: !!query.lastAttemptFrom ? query.lastAttemptFrom : '',
          lastAttemptTo: !!query.lastAttemptTo ? query.lastAttemptTo : '',
          timestamp: new Date().getTime(),
        });
        closeModal();
      },
      onClear: () => {
        const filter = isLgDown ? { lastAttemptFrom: null, lastAttemptTo: null } : {};
        setFilters({
          libraries: [],
          ...filter,
        });
        closeModal();
      },
      onCancel: () => closeModal(),
    } as ContentFilterModalProps);
  };

  const openReportFilterModal = () => {
    openModal('report-filter', {
      className: 'align-items-center pb-3',
      value: {
        platformId: searchTerm.platformId,
        lastAttemptFrom: Number(searchTerm.lastAttemptFrom || ''),
        lastAttemptTo: Number(searchTerm.lastAttemptTo || ''),
      },
      onAccept: query => {
        setFilters({
          ...query,
          lastAttemptFrom: !!query.lastAttemptFrom ? query.lastAttemptFrom : '',
          lastAttemptTo: !!query.lastAttemptTo ? query.lastAttemptTo : '',
        });
        closeModal();
      },
      onClear: () => {
        const filter = isLgDown ? { lastAttemptFrom: null, lastAttemptTo: null } : {};
        setFilters({
          platformId: null,
          ...filter,
        });
        closeModal();
      },
      onCancel: () => closeModal(),
    });
  };

  const searchTerm = useMemo(() => {
    const search = parse(searchParams.toString());
    queryClient.setQueryData([...queryKey], search);
    return search;
  }, [searchParams]);

  useEffect(() => {
    setSearchParams(
      prevValue => {
        const preQueries = parse(prevValue.toString());
        if (search === '') {
          delete preQueries.search;
          return stringify({ ...preQueries, ...filters });
        }
        return stringify({ ...preQueries, ...filters, search: search.trim() });
      },
      { replace: true },
    );
  }, [search, filters]);

  const filterCount = useMemo(() => {
    const totalFilters = calculateTotalFilterParams(searchTerm);

    return isLgDown ? totalFilters + Number(!!searchParams.get('lastAttemptFrom')?.length) : totalFilters;
  }, [searchTerm, isLgDown]);

  const { data, isFetching } = useQuery([...queryKey, searchTerm], async () => await fetchReports(searchTerm, userId), {
    cacheTime: 300000,
    staleTime: 300000,
  });

  const reportsData = useMemo(() => {
    if (!data?.length) return [];

    if (tableOptionsData?.enableExpanding) {
      return data.map(report => ({
        ...report,
        customExpandedRowComponent: <ExpandedContentAttempts attempts={report.attempts} />,
      }));
    }

    return data;
  }, [data, tableOptionsData?.enableExpanding]);

  const studentName = reportsData[0]?.user?.fullName;
  const unitName = searchParams.get('unit_name') || '';

  const lastAttemptFrom = useMemo(() => {
    const lastAttemptFromValue = Number(searchParams.get('lastAttemptFrom'));
    return lastAttemptFromValue ? new Date(lastAttemptFromValue * 1000) : null;
  }, [searchParams.get('lastAttemptFrom')]);

  const lastAttemptTo = useMemo(() => {
    const lastAttemptToValue = Number(searchParams.get('lastAttemptTo'));
    return lastAttemptToValue ? new Date(lastAttemptToValue * 1000) : null;
  }, [searchParams.get('lastAttemptTo')]);

  const renderSubHeader = () => {
    if (ltiMode) {
      return (
        <div className="w-100 mb-4 px-0 px-md-4">
          <div className="d-flex align-items-center gap-3">
            <div className="min-w-fit">
              <DateRangePicker
                fromDate={lastAttemptFrom}
                toDate={lastAttemptTo}
                onDatesChange={(startDate, endDate) =>
                  setFilters({ lastAttemptFrom: startDate, lastAttemptTo: endDate })
                }
                dataCy="last-attempt-filter"
              />
            </div>

            {!(studentName && unitName) ? (
              <Skeleton
                width={140}
                height={24}
              />
            ) : (
              <LtiModeLearnerInfo />
            )}
          </div>
        </div>
      );
    }

    return (
      <div className="w-100 mb-4 px-0 px-md-4">
        <div className="row">
          <div className="col col-md-6 d-none d-md-block">
            <DateRangePicker
              fromDate={lastAttemptFrom}
              toDate={lastAttemptTo}
              onDatesChange={(startDate, endDate) => setFilters({ lastAttemptFrom: startDate, lastAttemptTo: endDate })}
              dataCy="last-attempt-filter"
            />
          </div>
          <div className="col col-md-6 d-flex gap-3">
            {searchInputPlaceHolder.length ? (
              <Controller
                name="search"
                control={control}
                render={fields => {
                  return (
                    <div className="flex-fill">
                      <SearchBar
                        value={fields.field.value}
                        search={fields.field.onChange}
                        placeholder={searchInputPlaceHolder}
                      />
                    </div>
                  );
                }}
              />
            ) : (
              <></>
            )}

            {(canFilterByContentType || canFilterByPlatform) && (
              <button
                className={classNames('btn btn-outline-primary d-flex align-items-center', {
                  'bg-primary-50': filterCount > 0,
                })}
                onClick={() => (canFilterByPlatform ? openReportFilterModal() : openFilterModal())}
              >
                {filterCount === 0 ? (
                  <Filter
                    size={20}
                    className="primary-500 me-2"
                  />
                ) : (
                  <div
                    className="d-flex align-items-center justify-content-center rounded-circle p-1 bg-primary text-white font-size-12 h5 me-2 mb-0"
                    style={{ width: 20, height: 20 }}
                  >
                    {filterCount}
                  </div>
                )}

                <span>Filters</span>
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  const downloadCsvButton = () => {
    return (
      <button
        className="btn btn-primary text-nowrap"
        onClick={() => downloadCsv(searchTerm)}
        data-cy="download-reports-csv-btn"
        disabled={isDownloadLoading}
      >
        <Download
          className="d-none d-md-inline me-2"
          size={20}
        />
        <span>{`Export (.CSV)`}</span>

        {isDownloadLoading && (
          <span
            className="spinner-border spinner-border-sm ms-2"
            role="status"
            aria-hidden="true"
          ></span>
        )}
      </button>
    );
  };

  const learnerInfo = () => {
    return (
      <div className="w-100 d-flex align-items-center gap-2">
        {searchParams.get('user') && (
          <div
            className="gray-900 font-size-16 fw-semibold text-truncate"
            style={{ maxWidth: isLgDown ? 200 : 300 }}
          >
            {searchParams.get('user')}
          </div>
        )}
        {searchParams.get('platformName') && (
          <>
            <div
              className="bg-neutral-200 rounded-circle"
              style={{ width: 6, height: 6 }}
            />

            <div className="text-neutral-500 text-truncate flex-fill w-1px">{searchParams.get('platformName')}</div>
          </>
        )}
      </div>
    );
  };

  const LtiModeLearnerInfo = () => {
    return (
      <div className="d-flex align-items-center gap-2 text-truncate">
        {studentName && (
          <div
            className="gray-900 font-size-16 fw-semibold text-truncate"
            style={{ maxWidth: isLgDown ? 200 : 300 }}
          >
            {studentName}
          </div>
        )}

        {unitName && (
          <>
            <div
              className="bg-neutral-200 rounded-circle"
              style={{ width: 6, height: 6 }}
            />

            <div className="text-neutral-500 text-truncate">{unitName}</div>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {pageTitle?.length ? (
        <PageHeader
          title={pageTitle}
          rightElement={downloadCsvButton()}
        />
      ) : (
        <></>
      )}

      <div className={classNames('h-100 flex-fill d-flex flex-column', pageTitle.length ? 'p-3 p-md-4' : 'p-0')}>
        <div
          className={classNames('learner-report-layout flex-fill py-0 py-md-3', {
            'rounded-4': !ltiMode,
            'border-0': ltiMode,
          })}
        >
          {!ltiMode && (
            <>
              {crumbs.length > 0 && (
                <div className="d-none px-4 pb-3 mb-3 border-bottom border-gray-200 d-md-flex justify-content-between">
                  <div className="w-100 d-flex flex-column gap-1">
                    <AppBreadCrumb crumbs={crumbs} />

                    {learnerInfo()}
                  </div>

                  {!pageTitle.length && <div>{downloadCsvButton()}</div>}
                </div>
              )}

              {userId && (
                <div className="d-flex d-md-none mb-3">
                  <ArrowLeft
                    size={24}
                    className="text-neutral-600 cursor-pointer me-2"
                    onClick={() =>
                      navigate(crumbs[crumbs.length - 2].path, { replace: crumbs[crumbs.length - 2].replace })
                    }
                  />

                  {learnerInfo()}
                </div>
              )}
            </>
          )}

          <div className="learner-report-wrapper d-flex flex-column flex-fill">
            {renderSubHeader()}

            <LearnerReportsTable
              tableOptionsData={tableOptionsData}
              defaultColumns={defaultColumns}
              data={reportsData}
              isLoading={isFetching || isLoading}
              searchTerm={searchTerm}
              onRowClick={onRowClick}
            />
          </div>
        </div>
      </div>
    </>
  );
});

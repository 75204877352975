import apiClient from 'apis/ApiClient';
import { LibraryOption, User, UserQueryParams } from 'types';
import { Setting } from 'types/setting';

const endPoint = '/options';

export const fetchOptionUsers = async (params?: UserQueryParams): Promise<User[]> => {
  const { data } = await apiClient.get(`${endPoint}/users`, { params });

  return data;
};

export async function fetchOptionSettings(): Promise<Setting> {
  const response = await apiClient.get(`${endPoint}/settings`);
  return response.data;
}

export async function getOptionLibraries(): Promise<LibraryOption[]> {
  return await apiClient.get(`${endPoint}/libraries`).then(response => {
    return response.data;
  });
}

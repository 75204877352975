import { H5PPlayerUI } from '@lumieducation/h5p-react';
import { IPlayerModel } from '@lumieducation/h5p-server';
import { Role, SubscriptionStatus } from '@spiderbox/common';
import { getPlay } from 'apis/Content/Content';
import { ErrorEmptyBlock, Loading, ContentPermission, PastDueSubscriptionBanner } from 'components';
import { useGetContentRole } from 'hooks';
import { useAppStore } from 'hooks/useAppStore';
import React, { useEffect } from 'react';
import { Edit2 } from 'react-feather';
import { useMutation } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useMeasure } from 'react-use';
import './ContentViewerPage.scss';
import { pluralize } from 'utils/helpers';
import { observer } from 'mobx-react-lite';
import { sendGrade } from 'apis/Lti/Lti';
import { useContentUserData } from 'hooks/content/useContentUserData';
import { ContentUserDataEvent } from 'types';

export const ContentViewerPage = observer(() => {
  const { id: contentId } = useParams();
  const {
    workspaceStore: {
      setting: { contentBehavior },
    },
  } = useAppStore();
  const { contentRole } = useGetContentRole(contentId);
  const {
    userInfo,
    ltiStore: { contextId, ltiKey },
    subscriptionStore: { currentSubscription },
  } = useAppStore();

  const { finishedUserData, setFinishedAsync } = useContentUserData();

  const {
    mutateAsync: getPlayAsync,
    isLoading: isLoadingContentPlayer,
    isError: isErrorContentPlayer,
  } = useMutation({
    mutationFn: getPlay,
  });

  const { mutateAsync: sendGradeMutateAsync, isError: isSendGradeError } = useMutation({
    mutationFn: sendGrade,
    onSuccess: () => {
      (window.opener || window.parent)?.postMessage({ subject: 'finished.user.data' }, '*');
    },
  });

  const { search } = useLocation();
  const loadContentPlayAsync = async (
    contentId: string,
    contextId?: string,
    asUserId?: string,
    readOnlyState?: boolean,
  ): Promise<IPlayerModel> => {
    return await getPlayAsync({
      contentId,
      contextId,
      asUserId,
      readOnlyState,
    });
  };
  const showEditButton =
    (userInfo.roles?.includes(Role.MEMBER) || userInfo.roles?.includes(Role.ADMIN)) && !isErrorContentPlayer;
  const navigate = useNavigate();

  const [ref, { height }] = useMeasure();
  const handleXAPIEvent = async (statement: ContentUserDataEvent | any, context: any, event: any) => {
    if (!currentSubscription.studentReportItem) return;

    if (
      (event.getVerb() === 'completed' || event.getVerb() === 'answered') &&
      !event.getVerifiedStatementValue(['context', 'contextActivities', 'parent'])
    ) {
      await setFinishedAsync({ event, userId: userInfo.id, contextId });
      if (ltiKey) {
        await sendGradeMutateAsync({ ltiKey, contextId, contentId });
      }
    }
  };

  useEffect(() => {
    if ([SubscriptionStatus.CANCELED, SubscriptionStatus.UNPAID].includes(currentSubscription?.status)) return;

    (window.opener || window.parent)?.postMessage({ subject: 'lti.frameResize', height: height }, '*');
  }, [height, currentSubscription?.status]);

  if (!contentId) return <ErrorEmptyBlock />;

  if (!contextId) return <Loading />;

  return (
    <div
      className="content-view-page bg-white"
      ref={ref}
    >
      <PastDueSubscriptionBanner className="mb-2" />
      <ContentPermission
        role={contentRole}
        action="content.update"
      >
        {showEditButton && (
          <button
            className="btn btn-outline-primary mb-2"
            onClick={() => {
              const from = location.pathname;
              navigate(
                {
                  pathname: `../${contentId}/edit`,
                  search: search ? `${search}&backToPlayer=true` : `?backToPlayer=true`,
                },
                {
                  state: {
                    from,
                  },
                },
              );
            }}
          >
            <Edit2 size={20} />
            <span className="ms-2">Edit</span>
          </button>
        )}
      </ContentPermission>
      {isLoadingContentPlayer && <Loading />}
      {contentBehavior?.contentBehaviorDisplayTotalAttempts === 'true' && finishedUserData && (
        <div className="w-100 bg-primary-50 d-flex flex-column flex-md-row justify-content-center align-items-start align-items-md-center fw-semibold font-size-16 primary-500 py-2 ps-4">
          <p className="mb-1 mb-md-0 me-md-1">
            <span>You have made </span>{' '}
            <span className="total-attempts d-inline-flex justify-content-center align-items-center text-center text-white font-size-16 fw-semibold mx-1 p-2">
              {' '}
              {finishedUserData?.totalAttempts}
            </span>{' '}
            <span>{pluralize(finishedUserData?.totalAttempts, 'attempt', 's', false)}.</span>
          </p>

          <div className="last-percentage-score">
            You got correct {finishedUserData?.lastPercentageScore}% on your last attempt.
          </div>
        </div>
      )}

      {isSendGradeError && (
        <div className="w-100 bg-danger-50 d-flex justify-content-center align-items-center fw-semibold font-size-16 danger-500 py-2 text-bg-danger">
          Your gradebook did not confirm receiving this attempt.
        </div>
      )}

      <H5PPlayerUI
        contentId={contentId}
        contextId={contextId}
        asUserId={userInfo.id}
        loadContentCallback={loadContentPlayAsync}
        onxAPIStatement={async (statement, context, event) => {
          await handleXAPIEvent(statement, context, event);
        }}
      />
    </div>
  );
});

import React, { useMemo } from 'react';
import { UserDetailAccordion } from 'pages/UserDetailPage/components/UserDetailAccordion';
import { UserDetailEmpty } from 'pages/UserDetailPage/components/UserDetailEmpty';
import { useParams } from 'react-router-dom';
import { useGetFetchQuery } from 'hooks/useGetFetchQuery';
import { UserDetail } from 'types';
import { Role } from '@spiderbox/common';
import { useFormContext } from 'react-hook-form';
import './UserDetailCard.scss';

export const UserDetailCard = () => {
  const { userId: id } = useParams<{ userId?: string }>();

  const { roles } = useGetFetchQuery(['users', id, 'detail']) as UserDetail;

  const { watch } = useFormContext();

  const hasAdminPermission = useMemo(() => {
    return watch('roles') ? watch('roles') === Role.ADMIN : roles?.includes(Role.ADMIN);
  }, [watch('roles'), roles]);

  return (
    <div
      className="user-detail-card flex-fill d-flex flex-column bg-white mt-3"
      data-cy="user-permission-card"
    >
      <div className="border-bottom border-neutral-200 p-3 fw-bold fs-md text-neutral-900">User Permission</div>

      <div className="flex-fill d-grid gap-2 px-3 py-5">
        {hasAdminPermission ? <UserDetailAccordion /> : <UserDetailEmpty />}
      </div>
    </div>
  );
};

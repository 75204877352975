import React from 'react';
import UserDetailEmptyPng from 'assets/images/user-permission-empty.png';

export const UserDetailEmpty = () => {
  return (
    <div className="position-relative d-flex flex-column justify-content-center align-items-center py-5">
      <img
        className="mb-3"
        src={UserDetailEmptyPng}
        alt="UserDetailEmptySvg"
        data-cy="user-permission-empty__image"
      />
      <div
        className="text-center"
        data-cy="user-permission-empty__message"
      >
        Member can access to workspace and manage contents.
      </div>
    </div>
  );
};

import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import { ContentAttemptLayout } from '../components/ContentAttemptLayout';
import { ContentUserDataAttempt } from 'types/student-report';
import { ErrorResponse } from 'types';
import { fetchLearnerContentAttempt } from 'apis/LearnerReport/LearnerReport';
import { toast } from 'react-toastify';
import { fetchServerError } from 'utils';
import { CrumbItem } from 'components/AppBreadCrumb';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'hooks/useAppStore';

export const ContentUserAttemptDetails = observer(() => {
  const { attemptId, userId, id: contentId } = useParams();
  const [searchParam] = useSearchParams();
  const { ltiMode, ltiStore } = useAppStore();
  const studentReportEmail = searchParam.get('studentReportEmail') || '';

  const { data, isLoading } = useQuery<ContentUserDataAttempt, ErrorResponse>(
    ['learner-reports', 'attempts', attemptId],
    () =>
      fetchLearnerContentAttempt(userId, contentId, attemptId, {
        platformId: searchParam.get('platform'),
        studentReportEmail: studentReportEmail,
      }),
    {
      onError: (error: ErrorResponse) => {
        toast(fetchServerError(error), { type: 'error' });
      },
    },
  );

  const crumbs: CrumbItem[] = useMemo(() => {
    if (!data) return [];

    const baseUrl = ltiMode
      ? `/${ltiStore.workspaceId}/lti/launch/contents/${contentId}/reports`
      : `/contents/${contentId}/reports`;

    let crumbsData: CrumbItem[] = [];

    if (!ltiMode) {
      crumbsData = [{ path: `${baseUrl}?${searchParam}`, title: 'All Learners', replace: true }];
    }

    return [
      ...crumbsData,
      { path: `${baseUrl}/${userId}?${searchParam}`, title: data.user.fullName, replace: true },
      { path: '', title: `Attempt ${data.order + 1}`, unclickable: true },
    ];
  }, [data, searchParam, ltiMode]);

  return (
    <ContentAttemptLayout
      isLoading={isLoading}
      attempt={data}
      crumbs={crumbs}
    />
  );
});
